<template>
  <div class="box">
  <cube-tab-bar v-model="selectedLabel" show-slider style="position:fixed; width:100vw; background-color: white; height: 50px; z-index:1000000;">
    <cube-tab v-for="(tab, index) in tabs" :label="tab.label" :key="index">
    </cube-tab>
  </cube-tab-bar>
  <cube-tab-panels v-model="selectedLabel" >
    <cube-tab-panel v-for="(tab, index) in tabs" :label="tab.label" :key="index">
      <div>
        <div class="item-box1" v-if="selectedLabel === '任務'">
          <div v-if="tabs[0].log.length === 0 && empty === true" style="margin-top:25%; "><img src="" width="60%" /></div>
          <br><br>
          <div v-if="tabs[0].log.length !== 0" style="position:fixed; bottom: 35%; margin-left: 0px; left:0%;">
            <div style="font-size: 168px;">
               {{ tabs[0].log.length }}
            </div>
            <br><br><br><br><br><br>
            <div style="font-size: 20px; width: 100vw">總次數</div>
          </div>
          <!-- <ul class="list" style="position:fixed; left:0%; bottom: 50%; top:40%;" v-if="tabs[0].log.length !== 0">
            <div class="item">
              <div class="left"></div>
              <div class="middle" style="margin-top: -5px; ">
                <li class="title" style="font-size: 180px;"> {{ tabs[0].log.length }} <a style="font-size: 40px; margin-right: 0px; margin-top:50px"></a></li>
                <li class="entitle" style="color: black; font-size:20px; text-align:center; margin-top: 50%; margin-left: 5%"><div>總次數</div></li>
              </div>
              <div class="right"></div>
            </div>
          </ul> -->
          <div style=" background: white;line-height: 130px; font-size: 18px; border-radius:99em; height: 130px; width: 130px;  position:fixed; border: 1px solid rgba(101, 163, 80, 0.3); bottom: 5%; margin-left: -60px; left:50%;" v-if="tabs[0].log.length !== 0">
            <div style="font-weight: 600; ">
              跟著邱老闆喝
            </div>
          </div>
        </div>
        <div class="item-box" :style="{height: cal}" v-if="selectedLabel === '歷史記錄'">
          <div v-if="tabs[1].log.length === 0 && empty === true " style="margin-top:25%;"><img src="" width="60%" /></div>
          <ul class="list">
            <div class="item" style="background: white;margin:15px; box-shadow: 0 1px 5px 1px rgba(0, 0, 0, 0.1); border-radius: 5px;" v-for="(r, index) in tabs[1].log.record" :key=index>
              <i class="cubeic-right" style="width: 15vw; font-size:38px; color: var(--main-color);"/>
              <div class="left" style="width: 20vw;">
                <li class="title">任務日期</li>
                <li class="entitle">Mission day</li>
              </div>
                <!-- <li class="title">任務日期</li> -->
              <div class="middle" style="width:70px; margin-left: 30px; margin-top: -5px; ">
                <!-- <li class="title" v-for="(r, index) in tabs[1].log.record" :key=index>{{ r }}</li> -->
                <li class="title">{{ r }}</li>
              </div>
              <div class="right" style="margin-left: 50px; margin-top: -10px; ">
                <i class="cubeic-good"/>
              </div>
            </div>
          </ul>
        </div>
      </div>
    </cube-tab-panel>
  </cube-tab-panels>

  <div class="loading">
    <div class="ball"></div>
    <div class="ball"></div>
    <div class="ball"></div>
  </div>

  </div>
</template>

<script>
/* eslint-disable */
import { mapMutations, mapGetters, mapActions } from 'vuex'
import QrcodeVue from 'qrcode.vue';
import goodsData from  '@/components/data/goods.json'
/* eslint-disable */
export default {
  data () {
    return {
      selectedLabel: '任務',
      tabs: [{
        label: '任務',
        log: [],
      }, {
        label: '歷史記錄',
        log: [],
      },
    //   {
    //     label: '領取優惠券',
    //     coupon: couponContent
    //   }
      ],
      cal: '',
      empty: false,
      dailyLog: Object.values(daily),
      qrCode: '',
      exchangePage: false,
      item: {},
      loadingIcon: false,
      title: '',
      en_title: '',
      item_price: '',
      img: '',
      sizeArr: [],
      ices: [],
      sugars: [],
      drinksOption: [],
      foodsOption: [],
      iceSelect: '',
      sugarSelect: '',
      num: 1,
      //
      drinkDialog: '',
      beforeStep: '1',
      CityandArea: [],
      citylist: [],
      place: '',
      shopName: '',
      shopAddress: '',
      showList: false,
      page: '1',
      title: '',
      options: {
        pullUpLoad: true
      }
    }
  },
  props: ['phone'],
  components: {
    QrcodeVue,
  },
  mounted() {
    if (this.mission.length === 0) {
      const loading = document.querySelector('.loading');
      loading.classList.add('show');
      this.queryMission({phone: this.phone})
      .then((res) => {
        console.log(res);
        console.log(this.mission);
        if (res.data !== null) {
          this.storemission = Object.values(res.data);
          // const arr = []
          //   for (let i = 0; i < 110; i++) {
          //     arr.push(i)
          //   }
          // console.log(arr);
          // this.tabs[0].log = arr;
          this.tabs[0].log = this.mission[0].record
          this.tabs[1].log = this.mission[0]
          this.cal = (110 * this.tabs[0].log.length.toString()) + 'px'
          console.log(this.cal);

          // this.tabs[0].log = []
          // this.tabs[1].log = []
          if (this.tabs[0].log.length === 0 && this.tabs[1].log.length === 0) {
            this.empty = true;
          }
        } else {
          this.empty = true;
          loading.classList.remove('show');
        }
        // this.tabs[0].log = []
        // this.tabs[1].log = []
        loading.classList.remove('show');
      })
    } else {
      this.tabs[0].log = this.mission[0].record
      this.tabs[1].log = this.mission[0]
      this.cal = (110 * this.tabs[0].log.length.toString()) + 'px'
      if (this.tabs[0].log.length === 0 && this.tabs[1].log.length === 0) {
        this.empty = true;
      }
    }
  },
  watch: {
    tabs(val) {
      console.log(val);
    }
  },
  computed: {
    ...mapGetters({
      dailylogs: 'dailylogs',
      mission: 'mission'
    }),
    storemission: {
      get () { return this.mission },
      set(value) { this.updateMission({ mission: value }); },
    },
    storedailylog: {
      get () { return this.dailylogs },
      set(value) { this.updatedailylogs({ dailylog: value }); },
    }
  },
  methods: {
    ...mapActions({
      queryMission: 'queryMission'
    }),
    ...mapMutations({
      updatedailylogs: 'updatedailylogs',
      updateMission: 'updateMission'
    }),
    exchangeCoupon(id) {
      this.exchangePage = true
      this.qrCode = id;
      this.barCode = `https://bwipjs-api.metafloor.com/?bcid=code128&text=${id}&scaleY=0.3`;
    },
    decideItem(content) {
      console.log(content);
      this.drinkDialog = 'out';
      this.item.id = content.id
      this.item.set_name = content.set_name;
      this.item.redeem_start_datetime = content.redeem_start_datetime;
      this.item.redeem_end_datetime = content.redeem_end_datetime;
      this.item.image_url = content.image_url;

    },
    test(val) {
      this.beforeStep = '1';
      console.log(val);
      this.showList = true;
      // this.place = '';
    },
    plus() {
      if (this.item.num > 39) {
        return this.num;
      } else {
        this.item.num += 1;
      }
    },
    minus() {
      if (this.item.num < 2) {
        return this.num === 1;
      } else {
        this.item.num -= 1;
      }
    },
    close() {
      this.drinkDialog = '';
    },
    // 點擊選擇商品
    changeHandler(label) {
      // console.log(label);
      // this.current = label;
      // console.log('changed to:', label)
    },
    stickyChangeHandler(current) {
    },
    showCityList() {
      this.showList = true;
      this.place = '';
    },
    clickTitle(title) {
      console.log(title)
    },
    upsidedown(val) {
      console.log(val);
      this.showList = val
    },
  }
}
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
  font-family: 'Helvetica Neue', sans-serif;
  }
  .cube {
    z-index: 1000000000;
    margin-top: -15px;
    height: 20px;
    width: 100%;
    background-color: rgb(94, 94, 94);
  }
  #layer{
    z-index: 10000;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(181, 181, 181, 0.419);
  }
  .box {
    z-index: 0;
    padding-left: 0!important;
    padding-right: 0!important;
    margin: 0 auto;
    width: 100%;
    max-width: 540px;
    background: -webkit-linear-gradient(90deg,rgb(255, 255, 255), #2a8a15a3);
    // background-color: #ffffff;
    /* border: 1px solid rgb(170, 170, 170); */
    /* border:1px solid #8a8a8a; */
  }
  .loading {
    opacity: 0;
    display: flex;
    position: fixed;
    bottom: 50px;
    left: 0%;
    transform: translateX(0%);
    transition: opacity .3s ease-in;
  }

  .loading.show {
    opacity: 1;
  }

  .ball {
    background-color: #777;
    border-radius: 50%;
    margin: 5px;
    height: 10px;
    width: 10px;
    animation: jump .5s ease-in infinite;
  }

  .ball:nth-of-type(2) {
    animation-delay: 0.1s;
  }

  .ball:nth-of-type(3) {
    animation-delay: 0.2s;
  }
  @keyframes jump {
    0%, 100% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(-10px);
    }
  }
  #layer1{
    z-index: 100000000;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(181, 181, 181, 0.419);
  }
  .item-box1 {
    z-index: 10000000;
    padding-left: 0!important;
    padding-right: 0!important;
    height: 500px;
    margin-top: 15%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }
  .item-box {
    z-index: 10000000;
    padding-left: 0!important;
    padding-right: 0!important;
    // height: calc(100vh + 50px);
    // height: 5000px;
    margin-top: 15%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    // padding-bottom: 20px;
    overflow: scroll;
    -webkit-overflow-scrolling: touch;
  }
  .item-box .list{
    padding-left: 0!important;
    padding-right: 0!important;
    text-align: left;
    height: 100px;
    width: 100%;
    max-width: 400px;
    margin: 0px;
    padding: 0px;
    // overflow-y: auto;
    // -webkit-overflow-scrolling: touch;
    // padding-bottom: 70px;
  }
  .item-box .list .item {
    margin-top: 7px;
    display: flex;
    //overflow-y: auto;
    align-items: center;
    justify-content: space-between;
    align-content: space-between;
    // border-bottom: 1px solid rgba(210, 210, 210, 0.815);
    height: 85px;
    padding: 25px;
  }
  .item-box .list .item .middle .title {
    margin-top: 0px;
    color: rgba(0, 0, 0, 0.851);
    font-size: 16px;
    font-weight: 500;
    display: flex;
    letter-spacing: 1px;
    justify-content: space-between;
  }
  .item-box .list .item .middle .entitle {
    margin-top: 2px;
    transform: scale(0.8);
    margin-left: -28px;
    color: rgba(67, 67, 67, 0.851);
    font-size: 15px;
    font-weight: 400;
    letter-spacing: 1px;
  }
  .item-box .list .item .right {
    transform: scale(1.5);
    border-radius: 50%;
    // border: 1px solid  rgba(177, 198, 44, 0.877) ;
    background-clip: text;
    // -webkit-text-fill-color: transparent;
    // border: 1px solid rgba(112, 88, 88, 0.877);
    color: var(--main-color);
    margin-top: 30px;
    width: 20px;
    // margin-left: 0px;
  }
  .nameHint {
    width: 95%;
    height: 40px;
    margin: 10px auto;
    border: 1px solid #F37423;
    border-radius: 4px;
    background-color: #ffffff;
  }
  .drinkPopDia {
    // padding: 1em;
    position: absolute;
    z-index: 20000;
    // display: grid;
    // grid-gap: 15px;
    // position: fixed;
    margin-top: -590px;
    width: 100%;
    max-width: 600px;
    height: 800px;
    background-color: #f9f9f9;
    // overflow-y: auto;
    // -webkit-overflow-scrolling: touch;
    // background-color: #7e7e7e;
  }
  .drinkPopDia .movePart {
    position: relative;
    height: 500px;
    width: 100%;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }
  .drinkPopDia .drinktop {
    display: grid;
    grid-template-columns: 10% 70% 20%;
    position: fixed;
    z-index: 10000;
    width: 100%;
    height: 40px ;
    // background-color: rgba(255, 255, 255, 0.24);
    // box-shadow: 0 1px 5px 1px rgba(0, 0, 0, 0.4);
  }
  .drinkPopDia .title {
    display: grid;
    grid-gap: 5px;
    width:95%;
    padding: 10px;
    height: 60px;
    background-color: rgba(255, 255, 255, 0.933);
    margin: 0 auto;
    border-radius:5px;
    text-align: left;
    // background-color: rgba(255, 255, 255, 0.24);
    // box-shadow: 0 1px 5px 1px rgba(0, 0, 0, 0.4);
  }
  .drinkPopDia .item_contain {
    width:95%;
    height: 250px;
    // height: calc(100% - 250px);
    background-color: rgba(255, 255, 255, 0.933);
    margin: 0 auto;
    border-radius:5px;
    grid-template-columns: 2fr 2fr ;
    grid-template-rows:  2fr 2fr;
    grid-gap: 20px;
    padding: 10px;
  }
  .drinkPopDia .item_contain .item{
    text-align: left;
    color: #5b5b5b;
    width: 22em;
    max-width: 540px;
    height: 60px;
    margin: 15px 0px;
    // background: #ffa5a8;
  }
  .drinkPopDia .item_contain .item .button{
    color: #6e6e6e;
    background-color: transparent;
    width: 65px;
    height: 28px;
    margin: 5px;
    border-radius: 50px;
    font-size: 13px;
  }
</style>